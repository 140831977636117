import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'myt-expansion-panel',
  standalone: true,
  imports: [MatExpansionModule, NgClass],
  templateUrl: './expansion-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent {
  readonly panelOpenState = model<boolean>(false);
  readonly large = input<boolean>(false);
  readonly isHighlighted = input<boolean>(false);
  readonly divider = input<boolean>(false);
  readonly togglePosition = input<'before' | 'after'>('after');
  readonly panelType = input<'default' | 'primary'>('default');
}
